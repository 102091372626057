import React from "react";
import queryString from "query-string";
class Email extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      user: {},
    };
  }
  componentDidMount() {
    this.getUser();
    const { email_verify_token } = queryString.parse(
      this.props.location.search
    );
    if (email_verify_token) {
      this.activate(email_verify_token);
    }
  }
  getUser = () => {
    this.props
      .api("/my/user")
      .then((res) => res.json())
      .then((user) => this.setState({ user: user }));
  };
  activate = (token) => {
    this.props
      .api("/my/email_verify", {
        method: "POST",
        body: JSON.stringify({ email_verify_token: token }),
      })
      .then((res) => {
        if (res.status === 204) {
          this.getUser();
          alert("メールアドレスは有効化されました。");
        } else {
          alert("リンクは無効です。");
        }
      });
  };
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  reSent = () => {
    const { email } = this.state.user;
    this.props
      .api("/my/user", {
        method: "PUT",
        body: JSON.stringify({
          email,
        }),
      })
      .then((res) => {
        if (res.status === 200) {
          alert("有効化のためのURLを再送しました。");
        }
      });
  };
  submit = () => {
    const { email } = this.state;
    if (this.state.user.email === email) {
      alert("メールアドレスが変更されていません。");
      return;
    }
    this.props
      .api("/my/user", {
        method: "PUT",
        body: JSON.stringify({
          email,
        }),
      })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((user) => {
            this.setState({ user: user, email: "" });
            if (user.email) {
              alert(
                "変更を受け付けました。有効化のためのURLをメールで送信したのでご確認ください。"
              );
            }
          });
        } else if (res.status === 422) {
          res.json().then((body) => {
            alert(body);
          });
        }
      });
  };
  render() {
    const { user } = this.state;
    return (
      <div
        style={{
          height: "calc(100% - 10px)",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>メールアドレス更新</div>
        {user.email ? (
          <div className="fontSmall" style={{ marginTop: "5px" }}>
            現在のメールアドレス：{user.email}(
            {user.email_verified ? "有効" : "無効"})
          </div>
        ) : (
          <div className="fontSmall" style={{ marginTop: "5px" }}>
            メールアドレスは登録されていません
          </div>
        )}
        <input
          id="email"
          type="email"
          style={{ width: "100%", maxWidth: "400px" }}
          onChange={this.onChange}
        />
        <button
          className="submitButton"
          style={{ marginTop: "5px" }}
          onClick={this.submit}
        >
          更新
        </button>
        {!user.email || user.email_verified ? null : (
          <button
            className="submitButton"
            onClick={this.reSent}
            style={{ marginTop: "5px" }}
          >
            有効化メールの再送
          </button>
        )}
      </div>
    );
  }
}
export default Email;
