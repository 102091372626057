import React from "react";
import FileInput from "./components/FileInput";
class File extends React.Component {
  constructor(props) {
    super(props);
    this.state = { files: [], upload_files: [], uploading: false };
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    this.getFile();
  }

  delete(filename) {
    if (window.confirm(`${filename}を削除しますか？`)) {
      this.props
        .api(
          `/my/kansobuns/${this.props.match.params.kansobun_id}/files/${filename}`,
          {
            method: "DELETE",
          }
        )
        .then(() => {
          alert("削除しました");
          this.getFile();
        });
    }
  }
  getFile = () => {
    if (this.props.new) {
      return this.props;
    } else {
      return this.props
        .api(`/my/kansobuns/${this.props.match.params.kansobun_id}/files`)
        .then((res) => res.json())
        .then((res) => this.setState({ files: res }))
        .catch((error) => alert(error.message));
    }
  };

  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.files,
    });
  };

  createFile = () => {
    if (this.state.upload_files) {
      const { kansobun_id } = this.props.match.params;
      const totalSize = Array.from(this.state.upload_files).reduce(
        (acc, cur) => acc + cur.size,
        0
      );
      if (totalSize > 1024 * 1024 * 500) {
        if (
          !window.confirm(
            `ファイルサイズが${
              Math.ceil((totalSize * 10) / (1024 * 1024 * 1024)) / 10
            }GBあります！今アップロードして問題ありませんか？`
          )
        ) {
          return;
        }
      }
      const requests = Array.from(this.state.upload_files).map((file) => {
        return this.props
          .api(`/my/kansobuns/${kansobun_id}/files`, {
            method: "POST",
            body: JSON.stringify({ name: file.name }),
          })
          .then((res) => res.json())
          .then((res) => {
            const body = new FormData();
            // 何でファイル名つけるとだめなんだよ...ファイルサイズ大きいと400帰ってくる
            body.append('file', file);
            // body.append(file.name, file);
            const myHeaders = new Headers();
            return fetch(res.url, {
              mode: "cors",
              method: "POST",
              body: body,
            }).then(() => {
              this.setState({ upload_files: [] });
            });
          });
      });
      this.setState({ uploading: true });
      Promise.all(requests)
        .then(() => {
          alert("アップロードしました");
          this.getFile();
        }).catch((e)=>{
          // report error
          fetch("https://hooks.slack.com/services/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",{body: JSON.stringify({text: `${e.message}\n${e.stack}`}), method: 'POST'}).then(()=>console.log('aaa?'))
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    }
  };

  render() {
    const upload_files = this.state.upload_files
      ? Array.from(this.state.upload_files)
      : [];
    return (
      <div>
        {(() => {
          return (
            <React.Fragment>
              {this.props.showUpload ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    maxWidth: "500px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <FileInput
                    id="upload_files"
                    onChange={this.onChange}
                    style={{ width: "100%" }}
                  ></FileInput>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      textAlign: "right",
                    }}
                    className="fontSmall"
                  >
                    {upload_files.map((file, index) => {
                      return (
                        <div
                          style={{
                            borderBottom: "solid",
                            borderWidth: "1px",
                          }}
                          key={index}
                        >
                          {file.name}
                        </div>
                      );
                    })}
                  </div>
                  <button
                    style={{
                      marginTop: "5px",
                      display:
                        this.state.upload_files.length == 0 ? "none" : null,
                      width: "100%",
                    }}
                    className="submitButton"
                    onClick={this.createFile}
                  >
                    アップロード
                  </button>
                  <div
                    style={{
                      display: this.state.uploading ? "flex" : "none",
                      position: "fixed",
                      zIndex: 100,
                      width: "100vw",
                      height: "100vh",
                      top: "0px",
                      left: "0px",
                      flexFlow: "column",
                      justifyContent: "center",
                      backgroundColor: "hsla(180,0%,0%,0.8)",
                      color: "white",
                    }}
                  >
                    <div>アップロード中だよ。少し待ってね。</div>
                  </div>
                </div>
              ) : null}
              {this.state.files
                ? this.state.files.map((file, index) => {
                    const filename = decodeURI(
                      file.url.match(/\/([^\/]+)\?/)[1]
                    );
                    return (
                      <div
                        style={{
                          borderBottom: "solid",
                          borderWidth: "1px",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          marginTop: "5px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            fontWeight: "lighter",
                            fontFamily: "Sawarabi Mincho",
                            display: "flex",
                          }}
                          className="fontSmall"
                        >
                          {file.thumbnail ? (
                            <a href={file.url} download>
                              <img src={`${process.env.REACT_APP_API_BASE_URL}${file.thumbnail}`} />
                            </a>
                          ) : null}
                          {file.thumbnail ? null : (
                            <a href={file.url} download>
                              {filename}({Math.ceil(file.size*1000/(1024*1024*1024))/1000}GB)
                            </a>
                          )}
                          {this.props.showUpload ? (
                            <button
                              style={{ marginLeft: "auto" }}
                              onClick={() => this.delete(filename)}
                            >
                              削除
                            </button>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                : null}
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}
export default File;
