import React from "react";
import Comment from "./Comment";
import File from "./File";

class KansobunShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", title: {}, id: null, user_id: null, menuButtonTapped: false };
  }

  componentDidMount() {
    this.getKansobun();
  }

  getKansobun = () => {
    return this.props
      .api(
        `/my/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.json())
      .then((res) => this.setState(res))
      .catch((error) => alert(error.message));
  };

  textToHtml = (content) => {
    let regexp = /^# .+$/gm;
    let links = content.match(regexp);
    const marker = "${###===###}";
    let replaced = content.replace(regexp, marker);
    let splitted = replaced.split(marker);
    let tableOfContents = null;
    let text = null;

    // 目次を生成する
    // 目次からはidをターゲットにスクロールを実行する
    if (links) {
      tableOfContents = (
        <div style={{ textAlign: "right", borderBottom: "solid" }}>
          <div className="fontMedium">目次</div>
          {links.map((element, index) => {
            return (
              <div
                className="fontSmall"
                style={{ marginLeft: "auto" }}
                key={index}
              >
                <span
                  onClick={() => {
                    document.getElementById(index + 1).scrollIntoView(true);
                  }}
                >
                  {element.replace(/^# /, "")}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
    // 本文をスクロールターゲット用のidをつけて生成する
    text = (
      <div style={{ marginTop: "20px" }}>
        {splitted.map((element, index) => {
          return (
            <div
              id={index}
              key={index}
              style={{
                fontFamily: "Sawarabi Mincho",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              className="fontSmall"
            >
              {index > 0 && links ? links.shift().replace(/^# /, "") : null}
              {element}
            </div>
          );
        })}
      </div>
    );
    return (
      <React.Fragment>
        {tableOfContents}
        {text}
      </React.Fragment>
    );
  };

  menuButton = () => {
    if (this.state.menuButtonTapped) {
      return (
        <div>
          <button
            className="smallButton"
            onClick={() => {
              document.getElementById("comment").scrollIntoView(true);
            }}
          >comment</button>
          {this.props.user.id === this.state.user_id ? (
            <button
              className="smallButton"
              onClick={() => {
                this.props.history.push(
                  `/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}/edit`
                );
              }}
            >編集</button>
          ) : null}
          <button
            className="smallButton"
            onClick={() => {
              this.props.history.push(
                `/groups/${this.props.match.params.group_id}`
              );
            }}
          >戻る</button>
          <button
            className="smallButton"
            onClick={() => {
              document.getElementById("top").scrollIntoView(true);
            }}
          >top</button>
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }} id="top">
        <span
          style={{
            fontSize: "40px",
            marginLeft: "5%",
            marginRight: "10px",
            fontWeight: "lighter",
          }}
        >
          {this.state.title.value}
        </span>
        <span
          style={{
            fontSize: "20px",
            marginRight: "5%",
            fontWeight: "lighter",
          }}
        >
          by {this.state.user_id}
        </span>
        {(() => {
          return (
            <React.Fragment>
              <div
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                  marginTop: "20px",
                }}
              >
                {this.textToHtml(this.state.content)}
                <footer>
                  <div>
                    {this.menuButton()}
                    <button className="smallButton" onClick={() => {
                      this.setState({menuButtonTapped: !this.state.menuButtonTapped})
                    }}>menu</button>
                  </div>
                </footer>
                <div>
                  <File {...this.props} showUpload={this.props.user.id === this.state.user_id}></File>
                </div>
                <div id="comment">
                  <Comment {...this.props}></Comment>
                </div>
              </div>
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}

export default KansobunShow;
