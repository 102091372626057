import React from "react";
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      notifications: [],
    };
  }
  componentDidMount() {
    this.getNotifications();
  }
  toggle = () => {
    this.setState({ open: !this.state.open });
  };
  onClick = (notification) => {
    this.props.history.push(notification.link);
    this.read(notification);
  };
  read = (notification) => {
    this.props
      .api(`/my/notifications/${notification.id}`, {
        method: "PUT",
        body: JSON.stringify({ read: true }),
      })
      .then(() => {
        this.getNotifications();
      });
  };
  getNotifications() {
    this.props
      .api("/my/notifications")
      .then((res) => res.json())
      .then((notifications) => {
        this.setState({ notifications: notifications });
      });
  }
  render() {
    const { open, notifications } = this.state;
    const newNotification = notifications.find(
      (notification) => !notification.read
    );
    const defaultStyle = {
      backgroundColor: "red",
      minWidth: "2em",
      textAlign: "center",
      borderRadius: "10px",
    };
    const style = Object.assign(defaultStyle, this.props.style);
    if (newNotification) {
      return (
        <div style={style} onClick={this.toggle}>
          !
          <div
            style={{
              position: "absolute",
              zIndex: 99999999,
              display: open ? "flex" : "none",
              backgroundColor: "hsla(0,0%,0%,.5)",
              left: 0,
              top: 0,
              height: "100vh",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "black",
                borderColor: "black",
                borderWidth: "1px 1px 1px 1px",
              }}
            >
              <div style={{ color: "white", backgroundColor: "black" }}>
                お知らせ
              </div>
              {notifications.map((notification, index) => {
                return (
                  <div
                    style={{
                      color: "black",
                      borderColor: "black",
                      backgroundColor: "white",
                      borderWidth: "0px 1px 1px 1px",
                      borderStyle: "solid",
                      padding: "10px",
                    }}
                    onClick={() => {
                      this.onClick(notification);
                    }}
                    key={index}
                  >
                    {notification.read ? null : (
                      <span style={{ color: "red" }} className="fontSmall">
                        new
                      </span>
                    )}
                    {notification.content}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return <div style={style}></div>;
    }
  }
}
export default Notification;
