import React from "react";
class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { comments: [], buttonDisable: false };
  }

  componentDidMount() {
    console.log('comment didmount')
    this.getComment();
  }

  getComment = () => {
    if (this.props.new) {
      return this.props
    } else {
      return this.props
        .api(
          `/my/kansobuns/${this.props.match.params.kansobun_id}/comments`,
          {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.json())
        .then((res) => this.setState({ comments: res }))
        .catch((error) => alert(error.message));
    }
  };

  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  createComment = () => {
    if (this.state.content) {
      const { kansobun_id } = this.props.match.params;
      this.setState({buttonDisable: true})
      this.props
        .api(`/my/kansobuns/${kansobun_id}/comments`, {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.state),
        })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              throw new Error(error);
            });
          }
          return res.json();
        })
        .then((comment) => {
          this.state.comments.unshift(comment);
          this.setState({ comments: this.state.comments });
          this.setState({ content: "" });
        })
        .catch((error) => {
          alert(error.message);
        })
        .finally(() => {
          this.setState({buttonDisable: false})
        });
      
    }
  }

  render() {
    return (
      <div style={{ height: "300px" }}>
        {(() => {
          return (
            <React.Fragment>
              <div
                style={{
                  marginTop: "5%",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}>
                <textarea
                  placeholder="comment"
                  id="content"
                  value={this.state.content}
                  onChange={this.onChange}
                  style={{
                    width: "100%",
                    fontWeight: "lighter",
                    fontFamily: "Sawarabi Mincho",
                    height: "100px",
                  }}
                  className="fontSmall"
                ></textarea>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  disabled={this.state.buttonDisable}
                  className="submitButton"
                  onClick={this.createComment}>
                  コメントする
                    </button>
              </div>
              {this.state.comments ?
                this.state.comments.map((comment, index) => (
                  <div
                    style={{
                      marginLeft: "7%",
                      marginRight: "7%",
                      marginTop: "3%",
                      marginBottom: "2%",
                      borderBottom: "solid",
                      borderWidth: "1px",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                    key={index}
                    >
                    <div
                      style={{
                        fontWeight: "lighter",
                        fontFamily: "Sawarabi Mincho",
                      }}
                      className="fontSmall">{comment.content}</div>
                    <div
                      key={comment.user_id}
                      style={{
                        textAlign: "right"
                      }}
                      className="fontSmall">by {comment.user_id}</div>
                    <div
                      key={comment.comments_count}
                      style={{
                        textAlign: "right"
                      }}
                      className="fontSmall">
                      {(() => {
                        let text = "";
                        if (comment.comments_count > 0) {
                          text = comment.comments_count + "件";
                        } else {
                          text = "返信する"
                        }
                        return (
                          <a 
                            href="#"
                            onClick={(event) => {
                              this.props.history.push(
                                `/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}/comments/${comment.id}`
                              );
                              event.preventDefault();
                            }}
                          >
                            {text}
                          </a>
                        );
                      })()}
                    </div>
                  </div>
                ))
                : null}
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}
export default Comment;
