import React from "react";
class Reply extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      comment: { content: "", user_id: null, comment_id: null },
      reply: "",
      replies: [{ content: "", user_id: null, comment_id: null }]
    };
  }

  componentDidMount() {
    this.getComment();
    this.getReply();
  }

  getComment = () => {
    return this.props
      .api(
        `/my/comments/${this.props.match.params.comment_id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.json())
      .then((res) => this.setState({ comment: { content: res.content, user_id: res.user_id, comment_id: this.props.match.params.comment_id }}))
      .catch((error) => alert(error.message));
  };

  getReply = () => {
    if (this.props.new) {
      return this.props
    } else {
      return this.props
        .api(
          `/my/comments/${this.props.match.params.comment_id}/replies`,
          {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.json())
        .then((res) => this.setState({ replies: res }))
        .catch((error) => alert(error.message));
    }
  };

  onChange = (event) => {
    this.setState({ reply: event.target.value });
  };

  createComment = () => {
    if (this.state.reply) {
      const kansobun_id = this.props.match.params.kansobun_id;
      const reply = { content: this.state.reply, comment_id: this.state.comment.comment_id}
      this.props
        .api(`/my/kansobuns/${kansobun_id}/comments`, {
          method: "POST",
          body: JSON.stringify(reply),
        })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              throw new Error(error);
            });
          }
          return res.json();
        })
        .then((res) => {
          this.getReply()
          let reply = { content: this.state.reply, user_id: res.user_id }
          this.state.replies.unshift(reply);
          this.setState({ replies: this.state.replies });
          this.setState({ reply: "" })
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }

  render() {
    return (
      <div style={{ height: "300px" }}>
        {(() => {
          return (
            <React.Fragment>
              {this.state.comment ?
                 (
                  <div
                    style={{
                      marginLeft: "7%",
                      marginRight: "7%",
                      marginTop: "3%",
                      marginBottom: "2%",
                      borderBottom: "solid",
                      borderWidth: "1px",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                    >
                    <div
                      style={{
                        fontWeight: "lighter",
                        fontFamily: "Sawarabi Mincho",
                      }}
                      className="fontSmall">{this.state.comment.content}</div>
                    <div
                      style={{
                        textAlign: "right"
                      }}
                      className="fontSmall">by {this.state.comment.user_id}</div>
                    <div
                      style={{
                      marginTop: "5%",
                      marginLeft: "5%",
                      marginRight: "5%",
                      }}>
                      <textarea
                        placeholder="reply"
                        id="reply"
                        value={this.state.reply}
                        onChange={this.onChange}
                        style={{
                          width: "100%",
                          fontWeight: "lighter",
                          fontFamily: "Sawarabi Mincho",
                          height: "100px",
                        }}
                        className="fontSmall"
                      ></textarea>
                    </div>
                    <div
                      style={{
                      textAlign: "center",
                      marginTop: "20px",
                    }}>
                      <button className="submitButton" onClick={this.createComment}>
                        返信
                      </button>
                    </div>
                  </div>
                )
                : null}

              {this.state.replies ?
                      this.state.replies.map((reply, index) => (
                        <div
                          style={{
                            marginLeft: "7%",
                            marginRight: "7%",
                            marginTop: "3%",
                            marginBottom: "2%",
                            borderBottom: "solid",
                            borderWidth: "1px",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                          key={index}
                        >
                        <div
                          style={{
                            fontWeight: "lighter",
                            fontFamily: "Sawarabi Mincho",
                          }}
                          className="fontSmall">{reply.count}{reply.content}</div>
                        <div
                          key={reply.user_id}
                          style={{
                            textAlign: "right"
                          }}
                          className="fontSmall">by {reply.user_id}</div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                          }}>
                          <button
                            onClick={() => {
                              this.props.history.push(
                            `/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}/comments/${reply.id}`
                              );
                            }}
                            style={{
                              marginLeft: "5px",
                              backgroundColor: "#008BBB",
                              color: "#FFFFDD",
                            }}
                          >返信</button>
                    </div>
                  </div>
                ))
                : null}    
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}
export default Reply;
