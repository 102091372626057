import React from "react";
class GroupAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  submit = () => {
    this.props.api(`/my/groups`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        this.props.history.push(`/groups/${res.id}`);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    return (
      <div style={{ padding: "5px" }}>
        <div>グループを作成します</div>
        <div>
          <input
            placeholder="グループ名"
            id="name"
            value={this.state.name}
            onChange={this.onChange}
            style={{ width: "calc(100% - 10px)" }}
          ></input>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <button
            className="normalButton"
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            戻る
          </button>
          <button className="normalButton" onClick={this.submit} style={{marginLeft:"5px"}}>
            作成
          </button>
        </div>
      </div>
    );
  }
}
export default GroupAdd;
