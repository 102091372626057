import React from "react";
import queryString from "query-string";
class Password extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      reset_token: "",
    };
  }
  componentDidMount() {
    const { reset_token, email } = queryString.parse(
      this.props.location.search
    );
    if (reset_token) {
      this.setState({ reset_token, email });
    }
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  sendMail = () => {
    const { email } = this.state;
    this.props.api("/password_reset", {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
    });
  };
  submit = () => {
    const { password, reset_token, email } = this.state;
    if (password === "") {
      alert("パスワードを入力してください。");
      return;
    }
    this.props
      .api("/password_reset", {
        method: "PUT",
        body: JSON.stringify({
          password,
          reset_token,
          email,
        }),
      })
      .then((res) => {
        if (res.status === 403) {
          alert("URLは無効です。");
        } else if (res.status === 422) {
          res.json().then((message) => {
            alert(message);
          });
        } else if (res.status === 204) {
          alert("パスワードを再設定しました。");
          this.props.history.push("/login");
        }
      });
  };
  render() {
    const { reset_token } = this.state;
    return (
      <div
        style={{
          height: "calc(100% - 10px)",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>パスワード再設定用メール送信</div>
        {reset_token ? (
          <React.Fragment>
            <input
              id="password"
              type="password"
              style={{ width: "calc(100% - 10px)", maxWidth: "400px", marginTop: '5px' }}
              onChange={this.onChange}
              className='fontMedium'
              placeholder="password"
            />
            <button
              className="submitButton"
              style={{ marginTop: '5px' }}
              onClick={this.submit}
            >
              再設定
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              id="email"
              type="email"
              className='fontMedium'
              style={{ width: "calc(100% - 10px)", maxWidth: "400px", marginTop: '5px' }}
              onChange={this.onChange}
              placeholder="メールアドレス"
            />
            <button
              style={{ marginTop: '5px' }}
              onClick={this.sendMail}
            >
              送信
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default Password;
