import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Signup from "./Signup.js";
import Login from "./Login.js";
import Top from "./Top.js";
import GroupAdd from "./GroupAdd.js";
import Group from "./Group.js";
import KansobunAdd from "./KansobunAdd.js";
import TitleEdit from "./TitleEdit.js";
import TitleAdd from "./TitleAdd.js";
import "./App.css";
import Notification from "./Notification";
import KansobunShow from "./KansobunShow.js";
import Email from "./Email";
import Password from "./Password.js";
import Menu from "./Menu";
import Reply from "./Reply.js";
const styleConstants = {
  header: {
    height: "50px",
    padding: "5px",
  },
};
class App extends React.Component {
  constructor(props) {
    super(props);
    let login = localStorage.getItem("login");
    let user = localStorage.getItem("user");
    this.state = {
      login: login ? login : false,
      user: login ? JSON.parse(user) : null,
    };
  }
  componentDidMount() {
    if (
      !this.state.login &&
      !["/signup", "/password_reset"].some(
        (path) => this.props.location.pathname === path
      ) &&
      this.props.location.pathname !== "/login"
    ) {
      return this.props.history.push("/login");
    }
  }
  signup = (credential) => {
    this.api(`/my/signup`, {
      method: "POST",
      body: JSON.stringify(credential),
    })
      .then((res) => {
        if (res.status === 422) {
          return res.json().then((json) => {
            throw new Error(json);
          });
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ login: true, user: res });
        localStorage.setItem("login", true);
        localStorage.setItem("user", JSON.stringify(res));
        this.props.history.push("/");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  login = (credential) => {
    this.api(
      `/my/login`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credential),
      },
      false
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("ログインに失敗しました");
        }
        return res.json();
      })
      .then((res) => {
        this.setState({ login: true, user: res });
        localStorage.setItem("login", true);
        localStorage.setItem("user", JSON.stringify(res));
        this.props.history.push("/");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  logout = () => {
    return this.api(`/my/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      this.setState({ login: false, user: {} });
      localStorage.clear();
      this.props.history.push("/login");
    });
  };

  api = (path, params, authcheck = true) => {
    const api = fetch(
      `${process.env.REACT_APP_API_BASE_URL}${path}`,
      Object.assign(
        {
          mode: "cors",
          credentials: "include",
          headers:
            params && params.body instanceof FormData
              ? {}
              : {
                  "Content-Type": "application/json",
                },
        },
        params
      )
    );
    if (authcheck) {
      return api.then((res) => {
        if (res.status === 401 && this.state.login) {
          this.setState({ login: false, user: {} });
          localStorage.clear();
          this.props.history.push("/login");
          throw new Error("セッションが切れました");
        }
        return res;
      });
    } else {
      return api;
    }
  };
  render() {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          fontFamily: "M+PLUS+Rounded+1c",
        }}
      >
        <header
          style={{
            display: "flex",
            alignItems: "center",
            height: styleConstants.header.height,
            backgroundColor: "black",
            color: "white",
            textDecoration: "none",
            fontSize: "1.3rem",
          }}
        >
          <span
            onClick={() => {
              if (this.state.login) {
                this.props.history.push("/");
              }
            }}
            style={{ padding: styleConstants.header.padding }}
          >
            KANSOBUN
          </span>
          {this.state.login ? null : (
            <React.Fragment>
              <span
                style={{
                  marginLeft: "auto",
                  padding: styleConstants.header.padding,
                }}
                onClick={() => {
                  this.props.history.push("/login");
                }}
              >
                login
              </span>
              <span
                style={{ padding: styleConstants.header.padding }}
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                sign up
              </span>
            </React.Fragment>
          )}
          {this.state.login ? (
            <React.Fragment>
              <Notification
                style={{
                  marginLeft: "auto",
                  marginRight: styleConstants.header.padding,
                }}
                {...this.props}
                api={this.api}
              ></Notification>
              <Menu {...this.props} logout={this.logout} api={this.api} {...this.state}></Menu>
            </React.Fragment>
          ) : null}
        </header>
        <div
          style={{ height: `calc(100vh - ${styleConstants.header.height})` }}
        >
          <Switch>
            <Route path="/signup">
              {withRouter((props) => {
                return (
                  <Signup
                    {...props}
                    api={this.api}
                    signup={this.signup}
                  ></Signup>
                );
              })}
            </Route>
            <Route path="/login">
              <Login
                {...this.props}
                state={this.state}
                login={this.login}
              ></Login>
            </Route>
            <Route path="/password_reset">
              {withRouter((props) => {
                return <Password {...props} api={this.api}></Password>;
              })}
            </Route>
            {this.state.login ? (
              <Route path="/email">
                {withRouter((props) => {
                  return (
                    <Email {...props} state={this.state} api={this.api}></Email>
                  );
                })}
              </Route>
            ) : null}
            <Route path="/groups/new">
              <GroupAdd {...this.props} api={this.api}></GroupAdd>
            </Route>
            <Route exact path="/groups/:id">
              {withRouter((props) => {
                return (
                  <Group {...props} {...this.state} api={this.api}></Group>
                );
              })}
            </Route>
            <Route exact path="/groups/:group_id/titles/new">
              {withRouter((props) => {
                return <TitleAdd {...props} api={this.api}></TitleAdd>;
              })}
            </Route>
            <Route exact path="/groups/:group_id/titles/:title_id">
              {withRouter((props) => {
                return <TitleEdit {...props} api={this.api}></TitleEdit>;
              })}
            </Route>
            <Route
              exact
              path="/groups/:group_id/titles/:title_id/kansobuns/new"
            >
              {withRouter((props) => {
                return (
                  <KansobunAdd
                    {...props}
                    new={true}
                    {...this.state}
                    api={this.api}
                  ></KansobunAdd>
                );
              })}
            </Route>
            <Route
              exact
              path="/groups/:group_id/titles/:title_id/kansobuns/:kansobun_id"
            >
              {withRouter((props) => {
                return (
                  <KansobunShow
                    {...props}
                    {...this.state}
                    api={this.api}
                  ></KansobunShow>
                );
              })}
            </Route>
            <Route
              exact
              path="/groups/:group_id/titles/:title_id/kansobuns/:kansobun_id/edit"
            >
              {withRouter((props) => {
                return (
                  <KansobunAdd
                    {...props}
                    new={false}
                    {...this.state}
                    api={this.api}
                  ></KansobunAdd>
                );
              })}
            </Route>
            <Route
              exact
              path="/groups/:group_id/titles/:title_id/kansobuns/:kansobun_id/comments/:comment_id"
            >
              {withRouter((props) => {
                return (
                  <Reply {...props} {...this.state} api={this.api}></Reply>
                );
              })}
            </Route>
            <Route exact path="/">
              {withRouter((props) => {
                return <Top {...props} api={this.api} {...this.state}></Top>;
              })}
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default () => {
  return (
    <Router>
      <Route>
        {withRouter((props) => {
          return <App {...props}></App>;
        })}
      </Route>
    </Router>
  );
};
