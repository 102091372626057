import React from "react";
class TitleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", detail: "" };
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  submit = () => {
    this.props
      .api(`/my/groups/${this.props.match.params.group_id}/titles`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        this.props.history.push(`/groups/${res.group_id}`);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{
              width: "90%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            className="fontLarge"
          >
            <div style={{ height: "100px" }}>
              <div>タイトル追加</div>
              <input
                placeholder="タイトル"
                id="value"
                value={this.state.value}
                onChange={this.onChange}
                style={{
                  width: "100%",
                }}
                className="fontMedium"
              ></input>
            </div>
            <textarea
              placeholder="詳細"
              id="detail"
              type="text"
              value={this.state.detail}
              onChange={this.onChange}
              style={{
                width: "100%",
                height: "calc(100% - 220px)",
              }}
              className="fontSmall"
            ></textarea>
            <div style={{ height: "100px" }}>
              <button onClick={this.submit}>作成</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TitleAdd;
