import React from "react";
class FileInput extends React.Component {
  constructor() {
    super();
    this.fileInput = React.createRef();
  }
  render() {
    return (
      <div>
        <input
          id={this.props.id}
          onChange={this.props.onChange}
          ref={this.fileInput}
          style={{ display: "none" }}
          type="file"
          multiple="multiple"
        ></input>
        <button
          style={this.props.style}
          className="submitButton"
          onClick={() => {
            this.fileInput.current.click();
          }}
        >
          ファイルを選択
        </button>
      </div>
    );
  }
}
export default FileInput;
