import React from "react";
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: "", password: "" };
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  submit = () => {
    this.props.signup(this.state);
  };
  render() {
    const style = {
      marginTop: "5px",
    };
    return (
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          id="id"
          placeholder="id"
          value={this.state.id}
          onChange={this.onChange}
          style={style}
        ></input>
        <input
          id="password"
          placeholder="password"
          type="password"
          value={this.state.password}
          onChange={this.onChange}
          style={style}
        ></input>
        <button onClick={this.submit} style={style}>
          sign up
        </button>
      </div>
    );
  }
}
export default Signup;
