import React from "react";
import { Link } from "react-router-dom";

class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      invitations: [],
    };
  }
  componentDidMount() {
    if (this.props.login) {
      this.groups();
      this.getInvitations();
    }
  }
  getInvitations = () => {
    this.props
      .api("/my/invitations")
      .then((res) => res.json())
      .then((res) => this.setState({ invitations: res }));
  };
  join = (id) => {
    this.props
      .api(`/my/invitations/${id}`, {
        method: "PUT",
        body: JSON.stringify({ status: "accept" }),
      })
      .then(() => {
        this.groups();
        this.getInvitations();
      });
  };
  reject = (id) => {
    this.props
      .api(`/my/invitations/${id}`, {
        method: "PUT",
        body: JSON.stringify({ status: "reject" }),
      })
      .then(() => {
        this.getInvitations();
      });
  };
  groups = () => {
    return this.props
      .api(`/my/groups`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((res) => this.setState({ groups: res }))
      .catch((error) => {
        alert(error.message);
      });
  };
  render() {
    return (
      <div style={{ padding: "5px", height: "calc(100% - 10px)" }}>
        <div style={{ height: "calc(100% - 50px)" }}>
          <div style={{ display: "flex" }}>
            <div>所属グループ</div>
            <button
              className="normalButton"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                this.props.history.push("/groups/new");
              }}
            >
              グループ作成
            </button>
          </div>
          {this.state.groups.map((group) => (
            <div key={group.id}>
              <Link to={`/groups/${group.id}`}>
                {group.name ? group.name : "名無し"}
              </Link>
            </div>
          ))}
          { this.state.invitations.length !== 0 ? <div style={{ display: "flex", marginTop: "20px" }}>
            <div>グループからの招待</div>
          </div> : null }
          <div style={{ padding: "5px" }}>
            {this.state.invitations.map((invitation) => (
              <div key={invitation.id} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "calc(100vw - 190px)",
                    wordWrap: "break-word",
                  }}
                >
                  {invitation.group.name}
                </div>
                <div>
                  <button
                    className="submitButton"
                    style={{ padding: "0px", width: "80px" }}
                    onClick={() => this.join(invitation.id)}
                  >
                    参加
                  </button>
                </div>
                <div>
                  <button
                    style={{
                      padding: "0px",
                      width: "80px",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.reject(invitation.id)}
                  >
                    拒否
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default Top;
