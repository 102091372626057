import React from "react";
import { Link } from "react-router-dom";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: "", password: "" };
    if (props.state.login) {
      props.history.push("/");
    }
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  submit = () => {
    this.props.login(this.state);
  };
  render() {
    const style = {
      marginTop: '5px'
    }
    return (
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: 'center'
        }}
      >
        <input
          id="id"
          placeholder="id"
          value={this.state.id}
          onChange={this.onChange}
          style={style}
        ></input>
        <input
          id="password"
          placeholder="password"
          type="password"
          value={this.state.password}
          onChange={this.onChange}
 style={style}
        ></input>
        <button onClick={this.submit}  style={style}>
          login
        </button>
        <Link className='fontSmall' to="/password_reset" style={style}>
          パスワードを忘れた方はこちら
        </Link>
      </div>
    );
  }
}
export default Login;
