import React from "react";
class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      titles: [],
      kansobuns: [],
      invitations: [],
    };
  }

  componentDidMount() {
    if (this.props.login) {
      Promise.all([
        this.getGroup(),
        this.getTitles(),
        this.getKansobuns(),
        this.getInvitations(),
      ]).catch((error) => {
        alert(error.message);
      });
    }
  }

  getTitles = () => {
    return this.props
      .api(`/my/groups/${this.props.match.params.id}/titles`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((res) => this.setState({ titles: res }));
  };

  getKansobuns = () => {
    return this.props
      .api(`/my/groups/${this.props.match.params.id}/kansobuns`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((res) => this.setState({ kansobuns: res }));
  };

  getGroup = () => {
    return this.props
      .api(`/my/groups/${this.props.match.params.id}`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((res) => this.setState({ group: res }));
  };
  getInvitations = () => {
    return this.props
      .api(`/my/groups/${this.props.match.params.id}/invitations`)
      .then((res) => res.json())
      .then((res) => this.setState({ invitations: res }));
  };

  invite = (params) => {
    this.props
      .api(`/my/groups/${this.props.match.params.id}/invitations`, {
        method: "POST",
        body: JSON.stringify(params),
      })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 404) {
            throw new Error("ユーザが存在しません");
          }
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        let invitations = this.state.invitations;
        invitations.push(res);
        this.setState({ invitations: invitations });
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  addUser = (params) => {
    this.props
      .api(`/my/groups/${this.props.match.params.id}/users`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 404) {
            throw new Error("ユーザが存在しません");
          }
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        let group = this.state.group;
        group.users.push(res);
        this.setState({ group: group });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            marginLeft: "5%",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              fontSize: "40px",
            }}
          >
            {this.state.group.name}
          </div>
          <div
            style={{
              fontSize: "35px",
              fontWeight: "lighter",
              marginTop: "3%",
            }}
          >
            member
          </div>
          <UserAdd addUser={this.invite}></UserAdd>
          {this.state.group.users
            ? this.state.group.users.map((user) => (
                <div key={user.id}>■{user.id}</div>
              ))
            : null}
          {this.state.invitations.map((invitation) => (
            <div key={invitation.user_id}>■{invitation.user_id}(招待中)</div>
          ))}
          <div>
            <div
              style={{
                fontSize: "35px",
                fontWeight: "lighter",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              title
              <button
                className="normalButton"
                onClick={() => {
                  this.props.history.push(`${this.props.match.url}/titles/new`);
                }}
                style={{
                  marginLeft: "10px",
                }}
              >
                +
              </button>
            </div>
          </div>
          {this.state.titles.map((title) => (
            <div
              key={title.id}
              style={{
                marginBottom: "2rem",
              }}
            >
              <div style={{ borderStyle: "solid", borderWidth: "1px" }}></div>
              <div>{title.value}</div>
              <div className="fontSmall">
                <a
                  href="#"
                  onClick={() => {
                    this.props.history.push(
                      `${this.props.match.url}/titles/${title.id}`
                    );
                  }}
                >
                  タイトル編集
                </a>

                {(() => {
                  let path = "";
                  const kansobun = title.kansobuns.find(
                    (kansobun) => kansobun.user_id === this.props.user.id
                  );
                  if (kansobun) {
                    path = `${this.props.match.url}/titles/${title.id}/kansobuns/${kansobun.id}`;
                  } else {
                    path = `${this.props.match.url}/titles/${title.id}/kansobuns/new`;
                  }
                  return (
                    <a
                      href="#"
                      style={{ marginLeft: "1rem" }}
                      onClick={() => {
                        this.props.history.push(path);
                      }}
                    >
                      感想文を書く
                    </a>
                  );
                })()}
              </div>
              <div style={{ marginTop: "10px" }}>
                {title.kansobuns.map((kansobun) => (
                  <button
                    key={kansobun.id}
                    onClick={() => {
                      this.props.history.push(
                        `${this.props.match.url}/titles/${title.id}/kansobuns/${kansobun.id}`
                      );
                    }}
                    style={{
                      marginLeft: "5px",
                      backgroundColor: "#008BBB",
                      color: "#FFFFDD",
                    }}
                  >
                    {kansobun.user_id}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
    };
  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  submit = () => {
    this.props.addUser(this.state);
  };

  render() {
    return (
      <div
        style={{
          marginRight: "auto",
        }}
      >
        <input
          id="user_id"
          placeholder="ユーザID"
          value={this.state.user_id}
          onChange={this.onChange}
          style={{
            width: "60%",
          }}
        ></input>
        <button
          className="normalButton"
          onClick={this.submit}
          style={{
            marginLeft: "10px",
          }}
        >
          +
        </button>
      </div>
    );
  }
}
export default Group;
