import React from "react";
export default class TitleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      value: "",
      detail: "",
    };
  }
  componentDidMount() {
    this.getTitle();
  }

  getTitle = () => {
    return this.props
      .api(
        `/my/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.json())
      .then((res) => this.setState(res))
      .catch((error) => alert(error.message));
  };

  updateTitle = () => {
    return this.props
      .api(
        `/my/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}`,
        {
          method: "PUT",
          body: JSON.stringify(this.state),
        }
      )
      .then(() => {
        this.props.history.push(`/groups/${this.props.match.params.group_id}`);
      })
      .catch((error) => alert(error.message));
  };
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  render() {
    return (
      <div
        style={{
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "100%",
        }}
      >
        <div class="fontLarge" style={{ height: "3rem" }}>
          タイトル編集
        </div>
        <div class="fontMedium" style={{ height: "2rem" }}>
          タイトル
        </div>
        <div class="fontLarge" style={{ height: "3rem" }}>
          <input
            id="value"
            value={this.state.value}
            onChange={this.onChange}
          ></input>
        </div>
        <div class="fontMedium" style={{ height: "2rem" }}>
          詳細
        </div>
        <div style={{ height: "calc(100% - 14rem - 10px)" }}>
          <textarea
            id="detail"
            class="fontSmall"
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              height: "100%",
              resize: "none",
            }}
            value={this.state.detail}
            onChange={this.onChange}
          ></textarea>
        </div>
        <div
          class="fontLarge"
          style={{ height: "4rem", marginTop: "10px", textAlign: "center" }}
        >
          <button className="submitButton" onClick={this.updateTitle}>更新</button>
          <button
            className="normalButton"
            onClick={() => {
              this.props.history.push(
                `/groups/${this.props.match.params.group_id}`
              );
            }}
          >
            戻る
          </button>
        </div>
      </div>
    );
  }
}
