import React from "react";
class KansobunAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", title: {}, id: null, buttonDisable: false };
  }

  componentDidMount() {
    if (this.props.new) {
      this.getTitle();
    } else {
      this.getKansobun();
    }
  }

  getTitle = () => {
    return this.props
      .api(
        `/my/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.json())
      .then((res) => this.setState({ title: res }))
      .catch((error) => alert(error.message));
  };

  getKansobun = () => {
    return this.props
      .api(
        `/my/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.json())
      .then((res) => this.setState(res))
      .catch((error) => alert(error.message));
  };

  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  update = () => {
    const { group_id, title_id, kansobun_id } = this.props.match.params;
    this.props
      .api(
        `/my/groups/${group_id}/titles/${title_id}/kansobuns/${kansobun_id}`,
        {
          method: "PUT",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.state),
        }
      )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        this.props.history.push(
          `/groups/${group_id}/titles/${title_id}/kansobuns/${kansobun_id}`
        );
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  create = () => {
    const { group_id, title_id } = this.props.match.params;
    this.setState({buttonDisable: true})
    this.props
      .api(`/my/groups/${group_id}/titles/${title_id}/kansobuns`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((res) => {
        this.props.history.push(`/groups/${group_id}`);
      })
      .catch((error) => {
        alert(error.message);
      })
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <span
          style={{
            fontSize: "40px",
            marginLeft: "5%",
            marginRight: "10px",
            fontWeight: "lighter",
          }}
        >
          {this.state.title.value}
        </span>
        <span
          style={{
            fontSize: "20px",
            marginRight: "5%",
            fontWeight: "lighter",
          }}
        >
          by {this.state.user_id}
        </span>
        {(() => {
          return (
            <React.Fragment>
              <div style={{ display: "flex", height: "80%" }}>
                <textarea
                  placeholder="kansobun"
                  id="content"
                  value={this.state.content}
                  onChange={this.onChange}
                  style={{
                    width: "90%",
                    resize: "none",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  className="fontSmall"
                ></textarea>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {this.props.new ? (
                  <React.Fragment>
                    <button
                      disabled={this.state.buttonDisable}
                      className="submitButton"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={this.create}
                    >
                      作成
                    </button>
                    <button
                      disabled={this.state.buttonDisable}
                      className="normalButton"
                      onClick={() => {
                        this.props.history.push(
                          `/groups/${this.props.match.params.group_id}`
                        );
                      }}
                    >
                      キャンセル
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button
                      className="submitButton"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={this.update}
                    >
                      更新
                    </button>
                    <button
                      className="normalButton"
                      onClick={() => {
                        this.props.history.push(
                          `/groups/${this.props.match.params.group_id}/titles/${this.props.match.params.title_id}/kansobuns/${this.props.match.params.kansobun_id}`
                        );
                      }}
                    >
                      キャンセル
                    </button>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}
export default KansobunAdd;
