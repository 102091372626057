import React from "react";
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false, subscription: false };
  }
  render() {
    navigator.serviceWorker.ready.then(async (reg) => {
      const subscription = await reg.pushManager.getSubscription();
      if (subscription && !this.state.subscription) {
        this.setState({ subscription: true });
      }
    });
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <img
          alt="menu icon"
          style={{ width: "30px" }}
          src="/menu-black-18dp.svg"
          onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        />
        <div
          onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
          style={{
            display: this.state.menuOpen ? "block" : "none",
            position: "absolute",
            left: "0px",
            top: "0px",
            height: "100vh",
            width: "100vw",
            backgroundColor: "hsla(0,0%,0%,.5)",
          }}
        ></div>
        <div
          style={{
            display: this.state.menuOpen ? "block" : "none",
            position: "absolute",
            top: "50px",
            right: "0px",
            textAlign: "center",
            backgroundColor: "black",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div
            style={{
              borderBottom: "1px",
              boderColor: "white",
              borderBottomStyle: "solid",
            }}
          >
            ID: {this.props.user.id}
          </div>
          <div onClick={this.props.logout}>logout</div>
          <div
            onClick={() => {
              this.props.history.push("/email");
              this.setState({ menuOpen: false });
            }}
          >
            メールアドレス変更
          </div>
          <div
            onClick={() => {
              const registerSubscription = (subscription) => {
                this.props
                  .api("/my/push_subscribe", {
                    method: "POST",
                    body: JSON.stringify({
                      endpoint: subscription.toJSON().endpoint,
                      auth_secret: subscription.toJSON().keys.auth,
                      public_key: subscription.toJSON().keys.p256dh,
                    }),
                  })
                  .then(() => {
                    this.setState({ subscription: true });
                  });
              };
              navigator.serviceWorker.ready.then(async (reg) => {
                const subscription = await reg.pushManager.getSubscription();
                if (subscription) {
                  if (this.state.subscription) {
                    subscription.unsubscribe().then(() => {
                      this.setState({ subscription: false });
                    });
                  } else {
                    registerSubscription(subscription);
                  }
                } else {
                  return reg.pushManager
                    .subscribe({
                      userVisibleOnly: true,
                      applicationServerKey:
                        "BAnEkTi-hee4yDz8W9oBKzG5uwAtnGwUTQWwGqKXxT79cRBUtk2djec_owoHNF4pwD7H1P3hyEGgZ7-2IMwMG1Q",
                    })
                    .then((subscription) => {
                      registerSubscription(subscription);
                    })
                    .catch((e) => {
                      alert(e.message);
                    });
                }
              });
            }}
          >
            通知: {this.state.subscription ? "ON" : "OFF"}
          </div>
        </div>
      </div>
    );
  }
}
export default Menu;
